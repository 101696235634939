
body{
    background-image: url("../pictures/background.png");
    background-size: cover;
    background-position: center center;
    height: 100vh;
  width: 100vw;
}

html, body, #root {
   height: 100%;
   margin: 0;
   padding: 0;
 }

#contentContainer{
   width: 100%;
   padding-top: 40px;
   display: flex;
   justify-content: space-between;
}

.frontPageDiv{
   margin: 60px;
}

