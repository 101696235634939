#eliteserieTable {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    background-color: #333; /* Dark background similar to the image */
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Stronger shadow for emphasis */
}

.footballTable {
    width: 100%;
    border-collapse: collapse;
    color: white; /* Light text color for contrast */
    font-family: Arial, sans-serif; /* Clean font for readability */
}

.footballTable th, .footballTable td {
    padding: 8px 12px;
    border: none; /* Removes borders for a cleaner look */
    text-align: left;
}

.footballTable th {
    background-color: #000000; /* Darker header background */
    color: #f0f0f0; /* Slightly off-white text */
    font-weight: bold;
}

.footballTable tr {
    background-color: #333; /* Dark row background */
}

.footballTable tr:nth-child(even) {
    background-color: #3a3a3a; /* Slightly lighter shade for alternating rows */
}

.footballTable tr:hover {
    background-color: #555; /* Highlight on hover */
}

.footballTable td:nth-child(2) {

    color: #ffe98f; /* Yellow text color for contrast */
    font-weight: bold; /* Bold text for emphasis */
}