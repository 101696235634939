.navbar{
    padding: 0.5%;
    background-color: red;}

.mainTitle{
    margin-left: 20px !important;
    color: #ffffff;
    text-shadow: 
        -1px -1px 0 rgb(0, 0, 0),  
         1px -1px 0 rgb(0, 0, 0),
        -1px  1px 0 rgb(0, 0, 0),
         1px  1px 0 rgb(0, 0, 0);
    margin: 0;
    padding: 0;
 }